<template>
  <a-card :bordered="false">
    <a-table :columns="columns" :row-key="record => record.id" :data-source="data" :pagination="pagination"
      :loading="loading" @change="handleTableChange">
      <span slot="createTime" slot-scope="text">
        {{ text | dayjs }}
      </span>
    </a-table>
  </a-card>
</template>
<script>

import { smsLog } from '@/api/sms'

const columns = [
  {
    title: 'To',
    dataIndex: 'to'
  },
  {
    title: '内容',
    dataIndex: 'message',
    ellipsis: true
  },
  {
    title: '返回',
    dataIndex: 'result'
  },
  {
    title: '发送时间',
    dataIndex: 'create_time',
    sorter: true,
    scopedSlots: { customRender: 'createTime' }
  }
]

export default {
  data() {
    return {
      data: [],
      pagination: {},
      loading: false,
      columns
    }
  },
  mounted() {
    this.fetch()
  },
  methods: {
    handleTableChange(pagination, filters, sorter) {
      console.log(pagination)
      const pager = { ...this.pagination }
      pager.current = pagination.current
      this.pagination = pager
      this.fetch({
        results: pagination.pageSize,
        page: pagination.current,
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...filters
      })
    },
    fetch(params = {}) {
      this.loading = true
      new Promise((resolve, reject) => {
        smsLog(params).then(response => {
          if (response.status === 'success') {
            resolve(response)
          } else {
            reject(response)
          }
        })
      }).then(res => {
        console.log(res)
        const data = res.data
        const pagination = { ...this.pagination }
        this.loading = false
        this.data = data.data
        pagination.total = data.total
        pagination.pageSize = data.per_page
        pagination.current = data.current_page
        this.pagination = pagination
      })
    }
  }
}
</script>
